const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "发布时间",
                prop: "REPORT_DATE"
            }, {
                label: "二氧化硫(ppm)",
                prop: "SO2_1H_DENSITY"
            }, {
                label: "二氧化氮(ppm)",
                prop: "NO2_1H_DENSITY"
            }, {
                label: "臭氧(ppm)",
                prop: "O3_1H_DENSITY"
            }, {
                label: "颗粒物(µm)",
                prop: "KLW2D5_1H_DENSITY"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        // selectData: [{
        //     label: '部门',
        //     clearable: true,
        //     placeholder: "请选择部门",
        //     list: [],
        //     optionLabel: 'NAME',
        //     optionValue: 'ID',
        //     value: 'departmentValue',
        //     operateType: 'search',
        //     isShow: true
        // }, {
        //     label: '角色',
        //     clearable: true,
        //     placeholder: "请选择角色",
        //     list: [],
        //     optionLabel: 'NAME',
        //     optionValue: 'ID',
        //     value: 'roleValue',
        //     operateType: 'search',
        //     isShow: true
        // }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };